import './styles.css';

import { GatsbyBrowser } from 'gatsby';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = async ({
  location,
}) => {
  // @ts-ignore
  if (typeof window !== 'undefined' && window._hsq) {
    // @ts-ignore
    window._hsq.push(['trackPageView']);
  }

  function isDownloadPath() {
    if (
      typeof window !== 'undefined' &&
      window.location.href.includes('/downloads')
    ) {
      return true;
    }
    return false;
  }

  const baseScript = document.getElementById('hs-script-loader');
  const existingScript = document.getElementById('hs-script-web-interactive');

  const removeAllInteractive = function () {
    if (existingScript) {
      existingScript.remove();
      document.getElementById('hs-web-interactives-top-push-anchor')?.remove();
      document.getElementById('hs-web-interactives-top-anchor')?.remove();
      document.getElementById('hs-web-interactives-bottom-anchor')?.remove();
      document
        .getElementById('hs-web-interactives-floating-container')
        ?.remove();
    }
  };

  const addBaseScript = function () {
    const script = document.createElement('script');
    script.src = 'https://js-eu1.hs-scripts.com/25768951.js';
    script.async = true;
    script.defer = true;
    script.setAttribute('id', 'hs-script-loader');
    script.setAttribute('key', 'hs-script-loader');
    document.body.appendChild(script);
  };

  const addInteractiveScript = function () {
    // @ts-ignore
    window.hubspot_web_interactives_running = false;
    const script = document.createElement('script');
    script.src = 'https://js.hubspot.com/web-interactives-embed.js';
    script.setAttribute('id', 'hs-script-web-interactive');
    document.body.appendChild(script);
  };

  if (isDownloadPath()) {
    baseScript?.remove();
    removeAllInteractive();
  } else {
    if (!baseScript) {
      addBaseScript();
    }
    if (!existingScript) {
      addInteractiveScript();
    } else {
      removeAllInteractive();
      addInteractiveScript();
    }
  } //end else

  // Fetch the same page from the old side to populate logs for gatsby pages.
  await fetch('https://www2.knime.com' + location.pathname, {
    cache: 'no-cache',
    // mode: 'no-cors',
    headers: {
      'X-Referer': window.document.referrer,
      Referer: window.document.referrer,
    },
    referrerPolicy: 'unsafe-url',
  });
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = (
  args,
) => {
  // Tell Gatsby to only update scroll position if the pathname or hash has changed.
  // If only the search has changed (e.g. when a search form is submitted),
  // the scroll position should remain the same.
  const current = args.routerProps.location;
  const previous = args.prevRouterProps?.location;
  return (
    current.pathname !== previous?.pathname || current.hash !== previous?.hash
  );
};
