exports.components = {
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-events-on-demand-tsx": () => import("./../../../src/pages/events-on-demand.tsx" /* webpackChunkName: "component---src-pages-events-on-demand-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-knime-for-end-users-tsx": () => import("./../../../src/pages/knime-for-end-users.tsx" /* webpackChunkName: "component---src-pages-knime-for-end-users-tsx" */),
  "component---src-pages-knime-for-mlops-and-it-tsx": () => import("./../../../src/pages/knime-for-mlops-and-it.tsx" /* webpackChunkName: "component---src-pages-knime-for-mlops-and-it-tsx" */),
  "component---src-pages-partners-finder-tsx": () => import("./../../../src/pages/partners/finder.tsx" /* webpackChunkName: "component---src-pages-partners-finder-tsx" */),
  "component---src-pages-preview-company-blog-tsx": () => import("./../../../src/pages/__preview/company_blog.tsx" /* webpackChunkName: "component---src-pages-preview-company-blog-tsx" */),
  "component---src-pages-preview-event-tsx": () => import("./../../../src/pages/__preview/event.tsx" /* webpackChunkName: "component---src-pages-preview-event-tsx" */),
  "component---src-pages-preview-page-tsx": () => import("./../../../src/pages/__preview/page.tsx" /* webpackChunkName: "component---src-pages-preview-page-tsx" */),
  "component---src-pages-preview-partner-tsx": () => import("./../../../src/pages/__preview/partner.tsx" /* webpackChunkName: "component---src-pages-preview-partner-tsx" */),
  "component---src-pages-preview-success-story-tsx": () => import("./../../../src/pages/__preview/success_story.tsx" /* webpackChunkName: "component---src-pages-preview-success-story-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-blog-detail-tsx": () => import("./../../../src/templates/blog-detail.tsx" /* webpackChunkName: "component---src-templates-blog-detail-tsx" */),
  "component---src-templates-event-detail-tsx": () => import("./../../../src/templates/event-detail.tsx" /* webpackChunkName: "component---src-templates-event-detail-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */),
  "component---src-templates-success-story-tsx": () => import("./../../../src/templates/success-story.tsx" /* webpackChunkName: "component---src-templates-success-story-tsx" */)
}

